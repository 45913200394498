import { extendTheme, theme as nbTheme } from "native-base";

export const theme = extendTheme({
    colors: {
        backgroundTitleGreen: '#134E4A',
        backgroundTitleOrange: '#ffb969',
        backgroundTitleRed: '#EB5757',

        // primary: {
        //     [50]: nbTheme.colors.teal[50],
        //     [100]: nbTheme.colors.teal[100],
        //     [200]: nbTheme.colors.teal[200],
        //     [300]: nbTheme.colors.teal[300],
        //     [400]: nbTheme.colors.teal[400],
        //     [500]: nbTheme.colors.teal[500],
        //     [600]: nbTheme.colors.teal[600],
        //     [700]: nbTheme.colors.teal[700],
        //     [900]: nbTheme.colors.teal[900],
        // },
        clear: 'white',
        bg: {
            ['main']: '#fafafa',
            ['primary']: '#48887B',
            ['secundary']: '#efefef',
            ['alert']: '#EB5757',
            ['attention']: '#F2994A',
            ['information']: '#2D9CDB',
        },
        bd: {
            ['primary']: '#48887B',
            ['secundary']: '#efefef',
            ['terciary']: '#5c5c5c',
        },
        tx: {
            ['clear']: 'white',
            ['title']: '#333333',
            ['subTitle']: '#5c5c5c',
            ['primaryValue']: '#48887B',
            ['negativeValue']: '#EB5757',
            ['attentionValue']: '#F2994A',
            ['positiveValue']: '#219653',
            ['informationValue']: '#2D9CDB',
        },
        icon: {
            ['selected']: '#48887B',
            ['notSelected']: '#5c5c5c',
        },
        button: {
            ['bg']: '#48887B',
            ['hover']: '#325F56',
            ['text']: 'white',
        },
        outline: {
            ['bg']: '#efefef',
            ['text']: '#48887B',
        },
        input: {
            ['bg']: '#efefef',
            ['borderFocus']: '#48887B',
        },
    },
});