import React from "react";
import { Box, VStack, Text, Center, Stack } from "native-base";

export default function Loading(props: any) {
  return (
    <>
      <Center
        my="auto"
        _dark={{ bg: "coolGray.900" }}
        _light={{ bg: "primary.50" }}
        flex="1"
        p={{ md: 8 }}
      >
        <Stack
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          maxW={{ md: "1016px" }}
          flex={{ base: "1", md: "1" }}
        >
          <Center w="100%" flex={1}>
            <Box
              maxW="500"
              w="100%"
              px={{
                base: "4",
              }}
              py={{
                base: "8",
                md: "32",
              }}
              rounded={{ md: "xl" }}
              _light={{ bg: { md: "primary.50" } }}
              _dark={{ bg: { md: "coolGray.800" } }}
            >
              <VStack
                space="4"
                mb={{
                  base: "16",
                  md: "16",
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="xs">Carregando....</Text>
              </VStack>
            </Box>
          </Center>
        </Stack>
      </Center>
    </>
  );
}
