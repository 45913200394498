import React from "react";
import {
    Box,
    VStack,
    Button,
    Image,
    StatusBar,
    Center,
    Text,
} from "native-base";
import * as Linking from 'expo-linking';
import {useRoute} from "@react-navigation/native";
import {DeepLinkExtratoRouteProp} from "../../routes/param";
import PButton from "../base/pButton";

export default function Splash(props: any) {
    const route = useRoute<DeepLinkExtratoRouteProp>();
    return (
        <>
            <StatusBar
                translucent
                backgroundColor="transparent"
                barStyle="light-content"
            />
            <Box
                safeAreaTop
                _light={{bg: "bg.secundary"}}
            />

            <VStack
                alignItems="center"
                w="100%"
                flex="1"
                _light={{bg: "bg.secundary"}}
            >
                <Box maxW="500" w="100%">
                    <Center>
                        <Image
                            size={{base: "64"}}
                            resizeMode={"contain"}
                            alt="NativeBase Logo"
                            source={require("../../images/logo.png")}
                        />
                    </Center>

                    <Center
                        py={{
                            base: "4",
                            md: "4",
                        }}
                    >
                        <VStack
                            space="4"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text fontSize="4xl" fontWeight="bold" color="tx.primaryValue">
                                Seja bem-vindo!!
                            </Text>
                            <Text fontSize="xl" fontWeight="bold" color="tx.primaryValue">
                                Programa cashback da Palmed!
                            </Text>
                            <Text
                                _light={{
                                    color: "tx.subTitle",
                                }}
                            >
                                Compre e ganhe uma parte do dinheiro de volta :)
                            </Text>
                        </VStack>
                    </Center>

                    <Center justifyContent="center"
                            py={{
                                base: "16",
                                md: "16",
                            }}>
                        <Box
                            px={{
                                base: "4",
                            }}

                            maxW="500"
                            w="100%"
                            rounded={{md: "xl"}}
                        >
                            <VStack space="4">
                                <PButton
                                    text="Quero me cadastrar"
                                    onPress={() => {
                                        Linking.openURL('https://cadastro.grupopalmed.com.br/cadastro/cashback')
                                    }}
                                >
                                </PButton>

                                <PButton
                                    text="Já sou cadastrado"
                                    onPress={() => {
                                        props.navigation.navigate("SignIn");
                                    }}
                                >

                                </PButton>

                                <PButton
                                    text="Esqueceu sua senha?"
                                    onPress={() => {
                                        props.navigation.navigate("Forgot")
                                    }}
                                >
                                </PButton>
                            </VStack>
                        </Box>
                    </Center>
                </Box>
            </VStack>;
        </>
    );
}
