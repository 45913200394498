import {Button} from "native-base";
import React from "react";

export default function PButton(props: any) {
    return (<>
        <Button
            onPress={props.onPress}
            size="lg"
            borderRadius="12"
            _text={{
                color: "button.text",
                fontWeight: "medium",
            }}
            _hover={{
                bg: "button.hover",
            }}
            _light={{
                bg: "button.bg",
            }}
        >
            {props.text}
        </Button>
    </>)
}