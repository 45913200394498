import './global.css';

import React from "react";
import {NativeBaseProvider} from "native-base";
import {AuthContextProvider} from "./src/context/auth.context";
import {Routes} from "./src/routes";
import {theme} from "./src/config/theme";
import {INativebaseConfig} from "native-base/src/core/NativeBaseContext";


const config: INativebaseConfig = {
    theme: theme,
    strictMode: "off",
};

export default function App() {
    return (
        <NativeBaseProvider config={config}>
            <AuthContextProvider>
                <Routes></Routes>
            </AuthContextProvider>
        </NativeBaseProvider>
    );
}