import React from "react";
import {
  VStack,
  Text,
  useColorModeValue,
  Alert,
  Box,
  Slide,
} from "native-base";
import FloatingLabelInput from "../base/floatingLabelInput";
import LoginLayout from "./login.layout";
import fetcher from "../../services/fetcher";
import PButton from "../base/pButton";

export default function ForgotPassword(props: any) {
  const [cnpj, setCnpj] = React.useState("");
  const [isOpenTop, setIsOpenTop] = React.useState(false);

  const consultarCnpj = async (cnpj: string): Promise<void> => {
    setIsOpenTop(false);
    await fetcher<any>(
      'POST',
      'auth/recuperarSenha',
      // 'http://localhost:4002/extratocashback',
      {
        cnpj: cnpj,
      }
    )
      .then((res) => {
        if (res.ok) {
          if (res.return !== undefined) {
            if (!res.return.status) {
              setIsOpenTop(true);
            } else {
              props.navigation.navigate('SignIn');
            }
          }
        }
      })
      .catch((err) => {
        console.log((err as Error).message);
      });
  };
  return (
    <LoginLayout
      topTitle="Olá, esqueceu a senha?"
      topSubTitle=""
      topReturnText="Login"
      topRedirect="SignIn"
      footerTitle="Não tem uma conta?"
      footerSubTitle="Inscrever-se"
      footerRedirect="SignUp"
      image={require("../../images/forgot_password.svg")}
    >
      <VStack space={{ base: "2", md: "5" }}>
        <Text
          _light={{ color: "coolGray.800" }}
          _dark={{ color: "coolGray.400" }}
          textAlign={{ base: "left", sm: "left" }}
        >
          <strong>Não se preocupe!</strong>
          <br/>
          <br/>
          Digite o CNPJ associado à sua conta e
          enviaremos uma mensagem com detalhes sobre sua conta.
        </Text>
      </VStack>
      <VStack space="6" mt="5">
        <FloatingLabelInput
          py={{ base: "17px", md: "14px" }}
          isRequired
          label="CNPJ"
          type="cnpj"
          labelColor="#9CA3AF"
          borderRadius="4"
          defaultValue={cnpj}
          onChangeText={(txt: string) => setCnpj(txt)}
          labelBGColor={useColorModeValue("#fff", "#1F2937")}
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
        />
        <PButton
          onPress={() => {
            consultarCnpj(cnpj)
          }}
          text="Enviar"
        >

        </PButton>
        <PButton
          onPress={() => {
            props.navigation.navigate('SignIn');
          }}
          text="Voltar"
        >

        </PButton>
        <Box h="32" w="300">
          <Slide in={isOpenTop} placement="top">
            <Alert justifyContent="center" status="error">
              <Alert.Icon />
              <Text color="error.600" fontWeight="medium">
                Verifique o email informado, não foi possível encontrar o mesmo em nossa base de dados.
              </Text>
            </Alert>
          </Slide>
        </Box>
      </VStack>
    </LoginLayout>
  );
}
