import React from 'react';
import {
    Box,
    VStack,
    ScrollView,
    HStack,
    Icon,
    Text,
    Divider,
    Avatar,
    Button,
} from 'native-base';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {useAuthContext} from '../../context/auth.context';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';

const list = [
    {
        iconName: 'home',
        iconText: 'Home',
        route: 'DashBoard'
    }
];

function getFirstLetters(str: string) {
    const firstLetters = str
        .split(' ')
        .map((word) => word[0])
        .join('');
    return firstLetters.substring(0, 2);
}

export default function Sidebar() {
    const {session} = useAuthContext();
    const navi = useNavigation<StackNavigationProp<any>>();

    return (
        <Box
            w="80"
            borderRightWidth="1"
            display="flex"
            _light={{bg: 'clear', borderRightColor: 'bd.secundary'}}
        >
            <ScrollView>
                <VStack
                    px="4"
                    py="4"
                    space="4"
                    alignItems="center"
                    borderBottomWidth="1"
                    _light={{
                        borderBottomColor: 'bd.secundary',
                    }}
                >
                    <Avatar
                        // source={require('../../images/women.jpg')}
                        size="md"
                        _light={{backgroundColor: 'bg.primary', color: 'clear'}}
                    >
                        <Text
                            fontSize="md"
                            textAlign="center"
                            _light={{color: 'coolGray.50'}}
                        >
                            {getFirstLetters(session.usuarioPortal.RAZAOSOCIAL)}
                        </Text>
                    </Avatar>
                    <VStack flexWrap='wrap' alignItems="center" justifyContent="center">
                        <Text
                            fontSize="xs"
                            fontWeight="bold"
                            mb={2}
                            _light={{color: 'coolGray.800'}}
                        >
                            {session.usuarioPortal.RAZAOSOCIAL.substring(0, 37).concat(session.usuarioPortal.RAZAOSOCIAL.length > 37 ? '...' : '')}
                        </Text>
                        <Text
                            fontSize="xs"
                            fontWeight="bold"
                            _light={{color: 'coolGray.700'}}
                        >
                            {session.usuarioPortal.CNPJCPF}
                        </Text>
                        <Text
                            fontSize="xs"
                            fontWeight="bold"
                            _light={{color: 'coolGray.600'}}
                        >
                            {session.usuarioPortal.FANTASIA}
                        </Text>
                        <Text
                            fontSize="xs"
                            fontWeight="bold"
                            _light={{color: 'coolGray.600'}}
                        >
                            {session.usuarioPortal.RESP_NOME}
                        </Text>
                        <Text
                            fontSize="xs"
                            fontWeight="medium"
                            textAlign="center"
                            _light={{color: 'coolGray.300'}}
                        >
                            {session.usuarioPortal.EMAIL}
                        </Text>
                        <Text
                            fontSize="xs"
                            fontWeight="bold"
                            _light={{color: 'coolGray.500'}}
                        >
                            {`PIX : ${session.usuarioPortal.RESP_CHAVEPIX.toLowerCase()}`}
                        </Text>
                    </VStack>
                </VStack>
                <VStack px="4" py="4">
                    {list.map((item, idx) => {
                        return (
                            <Button
                                key={idx}
                                variant="ghost"
                                justifyContent="flex-start"
                                py="4"
                                px="4"
                                onPress={() => {
                                    navi.navigate('HomePages', {screen: item.route});
                                }}
                            >
                                <HStack space="4" alignItems="center">
                                    <Icon
                                        size="6"
                                        as={MaterialCommunityIcons}
                                        name={item.iconName}
                                        _light={{color: 'icon.selected'}}
                                    />
                                    <Text
                                        fontSize="md"
                                        fontWeight="medium"
                                        _light={{color: 'icon.selected'}}
                                    >
                                        {item.iconText}
                                    </Text>
                                </HStack>
                            </Button>
                        );
                    })}
                </VStack>
                <Divider _dark={{bgColor: 'bg.secundary'}}/>
                <VStack px="4" py="2">
                    <Button variant="ghost" justifyContent="flex-start" py="4" px="5">
                        <HStack space="4" alignItems="center">
                            <Icon
                                size="6"
                                as={MaterialCommunityIcons}
                                name="exit-to-app"
                                _light={{color: 'icon.selected'}}
                            />
                            <Text
                                fontSize="md"
                                fontWeight="medium"
                                _light={{color: 'icon.selected'}}
                            >
                                Logout
                            </Text>
                        </HStack>
                    </Button>
                </VStack>
            </ScrollView>
        </Box>
    );
}
