import React from "react";
import {
  Box,
  StatusBar,
  Center,
  Stack,
  HStack,
  VStack,
  Image,
  Hidden,
  Text,
  IconButton,
  Icon,
  Link,
} from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ImageSourcePropType } from "react-native";
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';

type LoginLayoutProps = {
  children: React.ReactNode;
  topReturnText: string;
  topTitle: string;
  topSubTitle: string;
  topRedirect: string;
  footerTitle: string;
  footerSubTitle: string;
  footerRedirect: string;
  image: ImageSourcePropType;
};

export function LoginReturnButton({ loginLayoutProps }: any) {
  const navigation = useNavigation();
  if (loginLayoutProps.topRedirect !== "") {
    return (
      <HStack space="2" alignItems="center">
        <IconButton
          variant="unstyled"
          pl="0"
          onPress={() => {
            navigation.navigate(loginLayoutProps.topRedirect);
          }}
          icon={
            <Icon
              size="6"
              as={AntDesign}
              name="arrowleft"
              color="coolGray.50"
            />
          }
        />
        <Text color="coolGray.50" fontSize="lg">
          {loginLayoutProps.topReturnText}
        </Text>
      </HStack>
    );
  } else {
    return null;
  }
}
export function LoginFooter({ loginLayoutProps }: any) {
  const navigation = useNavigation();
  return (
    <>
      <HStack
        mb="4"
        space="1"
        safeAreaBottom
        alignItems="center"
        justifyContent="center"
        mt={{ base: "auto", md: "8" }}
      >
        <Text
          _light={{ color: "tx.primaryValue" }}
        >
          {loginLayoutProps.footerTitle}
        </Text>
        {/* Opening Link Tag navigateTo:"SignUp" */}
        <Link
          _text={{
            fontWeight: "bold",
            textDecoration: "none",
          }}
          _light={{
            _text: {
              color: "tx.primaryValue" ,
            },
          }}
          onPress={() => {
            //navigation.navigate(loginLayoutProps.footerRedirect);
            Linking.openURL('https://cadastro.grupopalmed.com.br/cadastro/cashback')
          }}
        >
          {loginLayoutProps.footerSubTitle}
        </Link>
        {/* Closing Link Tag */}
      </HStack>
    </>
  );
}

export default function LoginLayout(
  loginLayoutProps: LoginLayoutProps
) {
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{ bg: "bg.secundary" }}
      />
      <KeyboardAwareScrollView
        contentContainerStyle={{ width: "100%", height: "100%" }}
        style={{ flex: 1 }}
      >
        <Center
          flex="1"
          my="auto"
          p={{ md: 8 }}
          _light={{ bg: "bg.primary" }}
        >
          <Stack
            w="100%"
            maxW={{ md: "1016px" }}
            flex={{ base: "1", md: undefined }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Hidden from="md">
              <VStack px="4" mt="4" mb="5" space="9">
                <LoginReturnButton
                  loginLayoutProps={loginLayoutProps}
                />
                <VStack space="2">
                  <Text fontSize="3xl" fontWeight="bold" color="tx.clear">
                    {loginLayoutProps.topTitle}
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight="normal"
                    _light={{
                      color: "tx.clear",
                    }}
                  >
                    {loginLayoutProps.topSubTitle}
                  </Text>
                </VStack>
              </VStack>
            </Hidden>
            <Hidden till="md">
              <Center
                flex="1"
                bg="bg.secundary"
                borderTopLeftRadius={{ base: "0", md: "xl" }}
                borderBottomLeftRadius={{ base: "0", md: "xl" }}
              >
                <Image
                  h="24"
                  size="80"
                  alt="Palmed Medicamentos"
                  resizeMode={"contain"}
                  source={loginLayoutProps.image}
                />
              </Center>
            </Hidden>
            <VStack
              flex="1"
              px="6"
              py="9"
              _light={{ bg: "white" }}
              _dark={{ bg: "coolGray.800" }}
              space="3"
              justifyContent="space-between"
              borderTopRightRadius={{ base: "2xl", md: "xl" }}
              borderBottomRightRadius={{ base: "0", md: "xl" }}
              borderTopLeftRadius={{ base: "2xl", md: "0" }}
            >
              <VStack space="7">
                <Hidden till="md">
                  <Text fontSize="xl" fontWeight="bold" color="tx.primaryValue">
                    {loginLayoutProps.topTitle}
                  </Text>
                </Hidden>
                {loginLayoutProps.children}
              </VStack>
              <LoginFooter loginLayoutProps={loginLayoutProps} />
            </VStack>
          </Stack>
        </Center>
      </KeyboardAwareScrollView>
    </>
  );
}
