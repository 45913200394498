    import React, { useState } from 'react';
import {
  VStack,
  Link,
  IconButton,
  Icon,
  Text,
  Box,
  Slide,
  Alert,
} from 'native-base';
import { Entypo } from '@expo/vector-icons';
import FloatingLabelInput from '../base/floatingLabelInput';
import LoginLayout from './login.layout';
import { useAuthContext } from '../../context/auth.context';
import PButton from "../base/pButton";

export function SignInForm({ props }: any) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [isOpenTop, setIsOpenTop] = React.useState(false);
  const { loginByEmail } = useAuthContext();

  const login = async (username: string, password: string) => {
    setIsOpenTop(false);
    const isAuthenticated = await loginByEmail(username, password);
    if (isAuthenticated) {
      props.navigation.navigate('HomePages');
    }
    else {
      setIsOpenTop(true);
    }
  }

  return (
    <VStack space="3">
      <VStack space={{ base: '7', md: '4' }}>
        <FloatingLabelInput
          isRequired
          label="Email"
          type="email"
          defaultValue={username}
          onChangeText={(txt: any) => setUsername(txt)}
        />
        <FloatingLabelInput
          isRequired
          label="Senha"
          type={showPass ? '' : 'password'}
          defaultValue={password}
          onChangeText={(txt: any) => setPassword(txt)}
          InputRightElement={
            <IconButton
              variant="unstyled"
              icon={
                <Icon
                  size="4"
                  color="coolGray.400"
                  as={Entypo}
                  name={showPass ? 'eye-with-line' : 'eye'}
                />
              }
              onPress={() => {
                setShowPass(true);
              }}
            />
          }
        />
      </VStack>
      <Link
        ml="auto"
        _text={{
          fontSize: 'xs',
          fontWeight: 'bold',
          textDecoration: 'none',
        }}
        _light={{
          _text: {
            color: 'tx.primaryValue',
          },
        }}
        onPress={() => {
          props.navigation.navigate('Forgot');
        }}
      >
        Esqueceu a senha?
      </Link>
      <PButton
        mt="5"
        onPress={() => {
          login(username, password);
        }}
        text="Entrar"
      >

      </PButton>
      <Box h="32" w="300">
        <Slide in={isOpenTop} placement="top">
          <Alert justifyContent="center" status="error">
            <Alert.Icon />
            <Text color="error.600" fontWeight="medium">
              Usuário ou senha inválido
            </Text>
          </Alert>
        </Slide>
      </Box>
    </VStack>
  );
}

export default function SignIn(props: any) {
  return (
    <LoginLayout
      topTitle="Bem vindo de volta,"
      topSubTitle="Faça login para continuar"
      topReturnText=""
      topRedirect=""
      footerTitle="Não tem uma conta?"
      footerSubTitle="Inscrever-se"
      footerRedirect="SignUp"
      image={require('../../images/logo.png')}
    >
      <SignInForm props={props} />
    </LoginLayout>
  );
}
