import React, {useEffect, useState} from 'react';
import {
    Box,
    VStack,
    StatusBar,
    HStack,
    Icon,
    Image,
    Text,
    Hidden,
    useColorMode,
    IconButton,
    Menu,
    Avatar,
    Input,
    Button,
    Center,
    ScrollView, Spacer, Collapse, Alert, CloseIcon,
} from 'native-base';
import {
    AntDesign,
    FontAwesome,
    Ionicons,
    MaterialCommunityIcons,
} from '@expo/vector-icons';

import Sidebar from './sidebar';

import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {Dimensions} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import {useNavigation} from '@react-navigation/native';
import {useAuthContext} from '../../context/auth.context';

type HomeLayoutProps = {
    scrollable?: boolean;
    displaySidebar?: boolean;
    displayBackButton?: boolean;
    showIcons?: boolean;
    displaySearchButton?: boolean;
    displayNotificationButton?: boolean;
    displayMenuButton?: boolean;
    header?: {
        searchbar: boolean;
    };
    mobileHeader?: {
        backButton: boolean;
    };
    children: React.ReactNode;
    showGroupInfoHeader?: boolean;
    displayBackIcon?: boolean;
    buttonSelect: number;
};

type MainContentProps = HomeLayoutProps;

type MobileHeaderProps = {
    backButton: boolean;
};

type HeaderProps = {
    toggleSidebar: () => void;
    menuButton: boolean;
    searchbar: boolean;
};

export function Header(props: HeaderProps) {
    const {colorMode} = useColorMode();
    return (
        <Box
            px="6"
            pt="3"
            pb="3"
            borderBottomWidth="1"
            _dark={{bg: 'coolGray.900', borderColor: 'coolGray.800'}}
            _light={{
                bg: {base: 'primary.900', md: 'white'},
                borderColor: 'coolGray.200',
            }}
        >
            <VStack
                alignSelf="center"
                width="100%"
                maxW={props.menuButton ? null : '1016px'}
            >
                <HStack alignItems="center" justifyContent="space-between">
                    <HStack space="4" alignItems="center">
                        {props.menuButton && (
                            <IconButton
                                variant="ghost"
                                colorScheme="light"
                                onPress={props.toggleSidebar}
                                icon={
                                    <Icon
                                        size="6"
                                        name="menu-sharp"
                                        as={Ionicons}
                                        _light={{color: 'coolGray.800'}}
                                        _dark={{color: 'coolGray.50'}}
                                    />
                                }
                            />
                        )}

                        {colorMode === 'light' ? (
                            <Image
                                h="10"
                                w="56"
                                alt="Palmed Medicamentos"
                                resizeMode="contain"
                                source={require('../../images/logo.png')}
                            />
                        ) : (
                            <Image
                                h="10"
                                w="56"
                                alt="Palmed Medicamentos"
                                resizeMode="contain"
                                source={require('../../images/logo.png')}
                            />
                        )}
                    </HStack>
                    {props.searchbar && (
                        <Input
                            px="4"
                            w="30%"
                            size="sm"
                            placeholder="Search"
                            InputLeftElement={
                                <Icon
                                    px="2"
                                    size="4"
                                    name={'search'}
                                    as={FontAwesome}
                                    _light={{
                                        color: 'coolGray.400',
                                    }}
                                    _dark={{
                                        color: 'coolGray.100',
                                    }}
                                />
                            }
                        />
                    )}
                    <HStack space="2" alignItems="center">
                        {/* <IconButton
              variant="ghost"
              colorScheme="light"
              icon={
                <Icon
                  size="6"
                  name="bell"
                  as={FontAwesome}
                  _dark={{
                    color: 'coolGray.200',
                  }}
                  _light={{
                    color: 'coolGray.400',
                  }}
                />
              }
            /> */}
                        <Menu
                            closeOnSelect={false}
                            w="200"
                            placement="bottom right"
                            onOpen={() => console.log('opened')}
                            onClose={() => console.log('closed')}
                            trigger={(triggerProps) => {
                                return (
                                    <IconButton
                                        {...triggerProps}
                                        variant="ghost"
                                        colorScheme="light"
                                        icon={
                                            <Avatar
                                                w="8"
                                                h="8"
                                                _dark={{bg: 'coolGray.200'}}
                                                //source={require('../../images/women.jpg')}
                                            />
                                        }
                                    />
                                );
                            }}
                            //@ts-ignore
                            _dark={{bg: 'coolGray.800', borderColor: 'coolGray.700'}}
                        >
                            <Menu.Group title="Perfil">
                                <Menu.Item>Logout</Menu.Item>
                            </Menu.Group>
                            {/* <Divider mt="3" w="100%" _dark={{ bg: 'coolGray.700' }} />
              <Menu.Group title="Shortcuts">
                <Menu.Item>Settings</Menu.Item>
                <Menu.Item>Logout</Menu.Item>
              </Menu.Group> */}
                        </Menu>
                    </HStack>
                </HStack>
            </VStack>
        </Box>
    );
}

function UrgentMessage() {
    const [show, setShow] = React.useState(true);
    return <Box mb={4} w="100%" alignItems="center">
        <Collapse isOpen={show}>
            <Alert maxW="400" status="error">
                <VStack space={1} flexShrink={1} w="100%">
                    <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                        <HStack flexShrink={1} space={2} alignItems="center">
                            <Alert.Icon/>
                            <Text fontSize="md" fontWeight="medium" _dark={{
                                color: "coolGray.800"
                            }}>
                                COMUNICADO IMPORTANTE!
                            </Text>
                        </HStack>
                        <IconButton variant="unstyled" _focus={{
                            borderWidth: 0
                        }} icon={<CloseIcon size="3"/>} _icon={{
                            color: "coolGray.600"
                        }} onPress={() => setShow(false)}/>
                    </HStack>
                    <Box pl="6" _dark={{
                        _text: {
                            color: "coolGray.600"
                        }
                    }}>
                        {`Informamos aos clientes cadastrados no programa Cashback que, conforme regulamento, os pontos não resgatados no prazo de um ano, expirarão.\n`}
                    </Box>
                </VStack>
            </Alert>
        </Collapse>
    </Box>;
}

export function MobileHeader(props: MobileHeaderProps) {
    const {session} = useAuthContext();
    return (
        <Box
            p="4"
            _light={{
                bg: {base: 'bg.primary', md: 'white'},
                borderColor: 'bd.primary',
            }}
        >
            <HStack
                flex="1"
                space="2"
                justifyContent="space-between"
            >
                <>
                    <HStack alignItems="center" space="2">
                        {props.backButton && (
                            <IconButton
                                variant="ghost"
                                colorScheme="light"
                                icon={
                                    <Icon
                                        size="6"
                                        as={AntDesign}
                                        name="arrowleft"
                                        color="coolGray.50"
                                    />
                                }
                            />
                        )}
                        <VStack>
                            <Text color="tx.clear" fontSize="sm" fontWeight="semibold">
                                Olá, {session.usuarioPortal.RAZAOSOCIAL}
                            </Text>
                            <Text color="tx.clear" fontSize="2xs">
                                CNPJ: {session.usuarioPortal.CNPJCPF}
                            </Text>
                            <Text color="tx.clear" fontSize="2xs">
                                Fantasia: {session.usuarioPortal.FANTASIA}
                            </Text>
                            <Text color="tx.clear" fontSize="2xs">
                                Comprador: {session.usuarioPortal.RESP_NOME}
                            </Text>
                            <Text color="tx.clear" fontSize="2xs">
                                {`PIX : ${session.usuarioPortal.RESP_CHAVEPIX.toLowerCase()}`}
                            </Text>
                        </VStack>
                    </HStack>
                    <HStack space="1">
                        {/* <IconButton
                variant="ghost"
                colorScheme="light"
                icon={
                  <Icon
                    size="6"
                    name="bell"
                    as={FontAwesome}
                    _dark={{
                      color: 'coolGray.200',
                    }}
                    _light={{
                      color: 'coolGray.50',
                    }}
                  />
                }
              /> */}
                        <Menu
                            w="150"
                            trigger={(triggerProps) => {
                                return (
                                    <IconButton
                                        variant="ghost"
                                        colorScheme="light"
                                        accessibilityLabel="Mais opções"
                                        {...triggerProps}
                                        icon={
                                            <Icon
                                                size="6"
                                                color="coolGray.50"
                                                name={'dots-vertical'}
                                                as={MaterialCommunityIcons}
                                            />
                                        }
                                    />
                                );
                            }}
                            placement="bottom right"
                            //@ts-ignore
                            _dark={{bg: 'coolGray.800', borderColor: 'coolGray.700'}}
                        >
                            <Menu.Item>Logout</Menu.Item>
                        </Menu>
                    </HStack>
                </>
            </HStack>
        </Box>
    );
}

function MobileFooter(props: any) {
    const navi = useNavigation<StackNavigationProp<any>>();

    return (
        <Hidden from="md">
            <HStack
                justifyContent="space-between"
                safeAreaBottom
                width="100%"
                position="absolute"
                left="0"
                right="0"
                bottom="0"
                overflow="hidden"
                alignSelf="center"
                _light={{backgroundColor: 'white'}}
            >
                <Button
                    flex={1}
                    variant="ghost"
                    colorScheme="coolGray"
                    _stack={{
                        flexDirection: 'column',
                    }}
                    startIcon={
                        <Icon
                            as={MaterialCommunityIcons}
                            name={props.buttonSelect === 0 ? "home" : "home-outline"}
                            size="5"
                            color="primary.900"
                        />
                    }
                    _text={{color: 'primary.900', bold: props.buttonSelect === 0}}
                    onPress={() => {
                        navi.navigate('HomePages', {screen: "DashBoard"})
                    }}
                >
                    Home
                </Button>
            </HStack>
        </Hidden>
    );
}

function MainContent(props: MainContentProps) {
    return (
        <VStack maxW="1016px" flex={1} width="100%" >
            <ScrollView showsHorizontalScrollIndicator={false}>
                <VStack
                    p={{base: 2}}
                    borderRadius={{base: '8'}}
                    flex={1}>
                    <UrgentMessage></UrgentMessage>
                    {props.children}
                </VStack>
            </ScrollView>
        </VStack>
    );
}

const window = Dimensions.get('window');
const screen = Dimensions.get('screen');

export default function HomeLayout({
                                       displaySidebar = true,
                                       header = {
                                           searchbar: false,
                                       },
                                       mobileHeader = {
                                           backButton: false,
                                       },
                                       ...props
                                   }: HomeLayoutProps) {
    const [isSidebarVisible, setIsSidebarVisible] = React.useState(true);
    const [dimensions, setDimensions] = useState({window, screen});

    function toggleSidebar() {
        setIsSidebarVisible(!isSidebarVisible);
    }

    useEffect(() => {
        Dimensions.addEventListener('change', ({window, screen}) => {
            setDimensions({window, screen});
        });
    });

    return (
        <>
            <StatusBar
                translucent
                barStyle="light-content"
                backgroundColor="transparent"
            />
            <Box
                safeAreaTop
                _light={{bg: 'bg.primary'}}
            />
            <VStack
                flex={1}
                _light={{
                    borderColor: 'coolGray.200',
                    bg: {base: 'bg.main'},
                }}
            >
                <KeyboardAwareScrollView
                    contentContainerStyle={{
                        width: dimensions.window.width,
                        height: dimensions.window.height,
                    }}
                >
                    <Hidden from="md">
                        <MobileHeader
                            backButton={mobileHeader.backButton}
                        />
                    </Hidden>
                    <Hidden till="md">
                        <Header
                            toggleSidebar={toggleSidebar}
                            menuButton={displaySidebar}
                            searchbar={header.searchbar}
                        />
                    </Hidden>
                    <Box
                        flex={1}
                        safeAreaBottom
                        flexDirection={{base: 'column', md: 'row'}}
                    >
                        {isSidebarVisible && displaySidebar && (
                            <Hidden till="md">
                                <Sidebar/>
                            </Hidden>
                        )}
                        <Hidden till="md">
                            <Center flex={1} p={{md: 4}}>
                                <MainContent
                                    {...props}
                                />
                            </Center>
                        </Hidden>
                        <Hidden from="md">
                            <MainContent {...props} />
                        </Hidden>
                    </Box>
                    {/* <MobileFooter {...props} /> */}
                </KeyboardAwareScrollView>
            </VStack>
        </>
    );
}
