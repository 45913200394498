import React, {useRef} from 'react';
import {SceneMap, TabView} from 'react-native-tab-view';
import {
    Box,
    Button,
    Hidden,
    HStack,
    Pressable,
    ScrollView,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from 'native-base';
import {Animated, Dimensions, GestureResponderEvent} from 'react-native';
import fetcher from '../../services/fetcher';
import {useAuthContext} from '../../context/auth.context';
import HomeLayout from '../home/home.layout';
import TicketCard from '../ticket/ticketCard';
import POutline from "../base/POutline";

type CardExtractProps = {
    extrato: TExtrato;
};

type CardNextExtractProps = {
    extrato: TExtratoProximaFaixa;
};

function CardExtract(props: CardExtractProps) {
    const {DESCRICAO, MES, ANO, SINAL, VLRLANCAMENTO} = props.extrato;
    return SINAL === '=' ? (
        <Box mt="4">
            <HStack
                borderWidth="1"
                borderRadius="lg"
                _light={{borderColor: 'bd.terciary', bg: 'bg.secundary'}}
                p="6"
                justifyContent="space-between"
                alignItems="center"
            >
                <VStack>
                    <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        _light={{color: 'tx.title'}}
                    >
                        Saldo Final : {MES}/{ANO}
                    </Text>
                    <VStack space="2" mt={1} textAlign="left">
                        <Text
                            fontSize="xs"
                            _light={{color: VLRLANCAMENTO <= 0 ? 'tx.negativeValue' : 'tx.positiveValue'}}
                            fontWeight="semibold"
                        >
                            {(VLRLANCAMENTO ?? 0).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </Text>
                    </VStack>
                </VStack>
            </HStack>
        </Box>
    ) : (
        <Box mt="4">
            <HStack
                borderWidth="2"
                borderRadius="lg"
                _light={{borderColor: 'bd.secundary', bg: 'clear'}}
                p="6"
                justifyContent="space-between"
                alignItems="center"
            >
                <VStack>
                    <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        _light={{color: 'tx.title'}}
                    >
                        {DESCRICAO}
                    </Text>
                    <VStack space="2" mt={1} textAlign="left">
                        <Text
                            fontSize="xs"
                            _light={{color: SINAL === '-' ? 'tx.negativeValue' : 'tx.positiveValue'}}
                            fontWeight="semibold"
                        >
                            {(VLRLANCAMENTO ?? 0).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </Text>
                    </VStack>
                </VStack>
            </HStack>
        </Box>
    );
}

function CardNextExtract(props: CardNextExtractProps) {
    const {session} = useAuthContext();
    const [dataProdutosCampanha, setProdutosCampanha] = React.useState<TProdutosAceleradores[]>();

    const {
        IDCASHBACK_PARAM_INDUSTRIA,
        DESCRICAO,
        VALOR_APURADO,
        COR_VALOR_CALCULADO,
        PRX_VALOR_DIF_FAIXA,
        PRX_PONTOS,
        TIPO_LISTA
    } = props.extrato;

    const TextoDescricao = () => {
        return <>
            <Text
                fontSize={{base: 'xs', md: 'sm'}}
                fontWeight="bold"
                _light={{color: 'tx.title'}}
            >
                {DESCRICAO}
            </Text>
        </>
    }
    const TextoComprasRealizadas = () => {
        return <>
            <Text
                fontSize={{base: 'xs', md: 'sm'}}
                _light={{color: 'tx.subTitle'}}
            >
                Compras realizadas <Text
                fontSize={{base: 'xs', md: 'sm'}}
                fontWeight="semibold"
                _light={{color: 'tx.primaryValue'}}
            >{(VALOR_APURADO ?? 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })}</Text>
            </Text>
        </>
    }
    const TextoPonto = () => {
        if (COR_VALOR_CALCULADO <= 0) {
            return <>
                <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    fontWeight="semibold"
                    _light={{color: 'tx.subTitle'}}
                >
                    Você ainda não pontuou!
                </Text>
            </>
        } else {
            return <>
                <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    fontWeight="semibold"
                    _light={{color: 'tx.subTitle'}}
                >
                    Já ganhou <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    fontWeight="semibold"
                    _light={{color: 'tx.primaryValue'}}
                >{COR_VALOR_CALCULADO}</Text> pontos!
                </Text>
            </>
        }
    }
    const TextoFalta = () => {
        if (COR_VALOR_CALCULADO <= 0) {
            return <>
                <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    _light={{color: 'tx.subTitle'}}
                >
                    Falta <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    fontWeight="semibold"
                    _light={{color: 'tx.attentionValue'}}
                >{(PRX_VALOR_DIF_FAIXA ?? 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })}</Text> para pontuar seus primeiros <Text
                    fontSize="sm"
                    fontWeight="semibold"
                    _light={{color: 'tx.attentionValue'}}
                >{PRX_PONTOS}</Text> pontos
                </Text>
            </>
        } else {
            return <>
                <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    _light={{color: 'tx.subTitle'}}
                >
                    Falta <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    fontWeight="semibold"
                    _light={{color: 'tx.attentionValue'}}
                >{(PRX_VALOR_DIF_FAIXA ?? 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })}</Text> para pontuar mais <Text
                    fontSize="sm"
                    fontWeight="semibold"
                    _light={{color: 'tx.attentionValue'}}
                >{PRX_PONTOS}</Text> pontos
                </Text>
            </>
        }
    }
    const ProdutosAceleradores = () => {
        const Cabecalho = () => {
            let cabecalho = null
            if (TIPO_LISTA === "FP" || TIPO_LISTA === "LP") {
                if (dataProdutosCampanha === undefined) {
                    cabecalho = <>
                        <Button
                            variant={"ghost"}
                            borderRadius="lg"
                            _hover={{bg: 'bg.secundary'}}
                            _text={{
                                color: 'tx.primaryValue',
                                fontSize: {base: 'xs', md: 'sm'},
                                fontWeight: 'semibold',
                                alignItems: 'start'
                            }}
                            p="4"
                            mt="4"
                            onPress={async () => {
                                await consultarProdutosCampanha(IDCASHBACK_PARAM_INDUSTRIA);
                            }}
                        > {TIPO_LISTA === 'FP'? 'Produtos Aceleradores' : 'Produtos Lista Palmed'}  </Button>
                    </>
                } else {
                    cabecalho = <>
                        <HStack
                            borderWidth="2"
                            borderRadius="lg"
                            _light={{borderColor: 'bd.secundary', bg: 'bg.secundary'}}
                            p="4"
                            mt="4"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text
                                fontSize={{base: 'xs', md: 'sm'}}
                                fontWeight="semibold"
                                _light={{color: 'tx.primaryValue'}}
                            >
                                {TIPO_LISTA === 'FP'? 'Produtos Aceleradores' : 'Produtos Lista Palmed'}
                            </Text>
                        </HStack>
                    </>
                }
            }
            return cabecalho
        }

        const Rodape = () => {
            let rodape = null
            if (TIPO_LISTA === "FP" || TIPO_LISTA === "LP")  {
                if (dataProdutosCampanha !== undefined) {
                    rodape = <>
                        <Button
                            variant={"ghost"}
                            borderRadius="lg"
                            _hover={{bg: 'bg.secundary'}}
                            _text={{
                                color: 'tx.primaryValue',
                                fontSize: {base: 'xs', md: 'sm'},
                                fontWeight: 'semibold',
                                alignItems: 'start'
                            }}
                            p="4"
                            mt="4"
                            onPress={async () => {
                                await setProdutosCampanha(undefined);

                            }}
                        > Ocultar Produtos </Button>
                    </>
                }
            }
            return rodape
        }

        return (
            <>
                <Cabecalho/>
                {
                    dataProdutosCampanha?.map((produto, index) => {
                        return (
                            <HStack
                                borderWidth="2"
                                borderRadius="lg"
                                _light={{borderColor: 'bd.secundary', bg: 'clear'}}
                                p="4"
                                mt={2}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <VStack space="2" flex={1}>
                                    <Text
                                        fontSize={{base: 'xs', md: 'sm'}}
                                        fontWeight="bold"
                                        _light={{color: 'tx.subTitle'}}>
                                        {`PRODUTO: `}
                                        <Text
                                            fontSize={{base: 'xs', md: 'sm'}}
                                            fontWeight="semibold"
                                            _light={{color: 'tx.subTitle'}}>
                                            {`${produto.CODPROD} - ${produto.DESCRICAO}`}
                                        </Text>
                                    </Text>
                                    <Text
                                        fontSize={{base: 'xs', md: 'sm'}}
                                        fontWeight="bold"
                                        _light={{color: 'tx.subTitle'}}>
                                        {`MARCA: `}
                                        <Text
                                            fontSize={{base: 'xs', md: 'sm'}}
                                            fontWeight="semibold"
                                            _light={{color: 'tx.subTitle'}}>
                                            {`${produto.MARCA}`}
                                        </Text>
                                    </Text>
                                    <Text
                                        fontSize={{base: 'xs', md: 'sm'}}
                                        fontWeight="bold"
                                        _light={{color: 'tx.subTitle'}}>
                                        {`CÓDIGO DE BARRA: `}
                                        <Text
                                            fontSize={{base: 'xs', md: 'sm'}}
                                            fontWeight="semibold"
                                            _light={{color: 'tx.subTitle'}}>
                                            {`${produto.CODAUXILIAR}`}
                                        </Text>
                                    </Text>
                                </VStack>
                            </HStack>
                        )
                    })
                }
                <Rodape/>
            </>
        )

    }
    const consultarProdutosCampanha = async (idParamIndustria: number): Promise<void> => {
        if (dataProdutosCampanha) return

        await fetcher<TProdutosAceleradores[]>(
            'POST',
            'cashback/extrato/produtos-aceleradores',
            {
                TOKEN: session.usuarioPortal.TOKEN,
                IDCASHBACK_PARAM_INDUSTRIA: idParamIndustria,
            }
        )
            .then((res) => {
                if (res.ok) {
                    if (res.return.data !== undefined) {
                        setProdutosCampanha(res.return.data)
                    }
                }
            })
            .catch((err) => {
                console.log((err as Error).message);
            });
    };


    return (
        <Box mt="4">
            <VStack
                borderWidth="1"
                borderRadius="lg"
                _light={{borderColor: 'bd.secundary', bg: 'clear'}}
                p="6"
                space={2}
            >
                <HStack>
                    <Hidden from='md'>
                        <>
                            <VStack space={2}>
                                <TextoDescricao/>
                                <TextoPonto/>
                            </VStack>
                        </>
                    </Hidden>
                    <Hidden till='md'>
                        <TextoDescricao/>
                    </Hidden>
                </HStack>
                <HStack>
                    <TextoComprasRealizadas/>
                    <Hidden till='md'>
                        <>
                            <Spacer/>
                            <TextoPonto/>
                        </>
                    </Hidden>
                </HStack>
                <HStack>
                    <TextoFalta/>
                </HStack>
                <ProdutosAceleradores/>
            </VStack>
        </Box>
    )
}

export default function Extrato() {
    const {session} = useAuthContext();
    const [index, setIndex] = React.useState(0);
    const [dataExtratoCashBack, setExtratoCashBack] = React.useState<TExtratoCashBack>();
    const scrollViewRef = useRef<any>();
    const [routes] = React.useState([
        {
            key: 'one',
            title: 'Resumo',
        },
        {
            key: 'two',
            title: 'Extrato',
        },
        {
            key: 'three',
            title: 'Saldo p/ Laboratório',
        },
        {
            key: 'four',
            title: 'Resgates',
        },
        {
            key: 'five',
            title: 'Metas',
        },
    ]);

    const renderTabBar = (props: {
            navigationState: { routes: any[] };
            position: {
                interpolate: (arg0: { inputRange: any; outputRange: any }) => any;
            };
        }) => {
            const inputRange = props.navigationState.routes.map((x, i) => i);
            return (
                <Box flexDirection="row">
                    <ScrollView
                        flex={1}
                        horizontal
                        ref={scrollViewRef}
                        showsHorizontalScrollIndicator={false}
                    >
                        {props.navigationState.routes.map((route, i) => {
                            const opacity = props.position.interpolate({
                                inputRange,
                                outputRange: inputRange.map((inputIndex) =>
                                    inputIndex === i ? 1 : 0.5
                                ),
                            });
                            const color =
                                index === i
                                    ? useColorModeValue('bg.primary', 'primary.300')
                                    : useColorModeValue('bg.secundary', 'gray.100');
                            const borderColor =
                                index === i
                                    ? useColorModeValue('bg.primary', 'primary.300')
                                    : useColorModeValue('bg.secundary', 'gray.100');
                            return (
                                <Box key={i} mr={2}>
                                    <Hidden from="md">
                                        <Box
                                            key={i}
                                            borderBottomWidth="3"
                                            borderColor={borderColor}
                                            p="4"
                                        >
                                            <Pressable
                                                onPress={(event: GestureResponderEvent) => {
                                                    const {pageX, pageY} = event.nativeEvent;
                                                    if (scrollViewRef) {
                                                        scrollViewRef.current.scrollTo({
                                                            x: pageX - 100,
                                                            y: pageY,
                                                            animated: true
                                                        })
                                                    }
                                                    setIndex(i);
                                                }}
                                            >
                                                <Animated.Text
                                                    style={{
                                                        color,
                                                    }}
                                                >
                                                    {route.title}
                                                </Animated.Text>
                                            </Pressable>
                                        </Box>
                                    </Hidden>
                                    <Hidden till="md">
                                        <Box
                                            key={i}
                                            borderBottomWidth="3"
                                            borderColor={borderColor}
                                            p="4"
                                            //cursor="pointer"
                                        >
                                            <Pressable
                                                onPress={(event: GestureResponderEvent) => {
                                                    const {pageX, pageY} = event.nativeEvent;
                                                    if (scrollViewRef) {
                                                        scrollViewRef.current.scrollTo({
                                                            x: pageX - 100,
                                                            y: pageY,
                                                            animated: true
                                                        })
                                                    }
                                                    setIndex(i);
                                                }}
                                            >
                                                <Animated.Text
                                                    style={{
                                                        color,
                                                    }}
                                                >
                                                    {route.title}
                                                </Animated.Text>
                                            </Pressable>
                                        </Box>
                                    </Hidden>
                                </Box>
                            );
                        })}
                    </ScrollView>
                </Box>
            );
        }
    ;

    const routeOne = () => {
        return (
            <>
                <ScrollView maxHeight={"70vh"} showsHorizontalScrollIndicator={true}>

                    <Stack
                        my={4}
                        direction={{base: 'column', md: 'row'}}
                        alignItems={'center'}
                        justifyContent={{md: 'space-around'}}
                    >
                        <VStack
                            flex={1}
                            borderRadius="lg"
                            borderWidth={1}
                            borderColor="bd.secundary"
                            width={{base: '-webkit-fill-available', md: ''}}
                            height={{md: 115}}
                        >
                            <Box
                                padding={2}
                                borderTopLeftRadius={"lg"}
                                borderTopRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'bg.information'}}>
                                <Text fontSize={{base: 'md', md: 'xl'}}
                                      fontWeight="semibold"
                                      _light={{color: 'tx.clear'}}
                                > Disponível para Saque

                                </Text>
                            </Box>
                            <Box
                                flex={1}
                                padding={2}
                                alignItems={'center'}
                                justifyContent={'center'}
                                borderBottomLeftRadius={"lg"}
                                borderBottomRightRadius={"lg"}
                                _light={{bg: 'clear'}}>
                                <Text
                                    fontSize={'xl'}
                                    bold
                                    _light={{color: 'tx.informationValue'}}
                                >
                                    {dataExtratoCashBack === undefined ? 0 : (dataExtratoCashBack.extrato.filter((el) => el.POSICAO === 1)[0].VLRLANCAMENTO || 0).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </Text>
                            </Box>
                        </VStack>
                    </Stack>

                    <Stack
                        mt={2}
                        direction={{base: 'column', md: 'row'}}
                        alignItems={'center'}
                        justifyContent={{md: 'space-around'}}
                    >
                        <VStack
                            flex={1}
                            mr={{md: 2}}
                            mb={4}
                            width={{base: '-webkit-fill-available', md: ''}}
                            height={{md: 115}}
                        >
                            <Box
                                padding={2}
                                borderTopLeftRadius={"lg"}
                                borderTopRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'bg.alert'}}>
                                <Text fontSize={{base: 'md', md: 'xl'}}
                                      fontWeight="semibold"
                                      _light={{color: 'clear'}}
                                >Já Resgatado</Text>
                            </Box>
                            <Box
                                mb={2}
                                padding={2}
                                flex={1}
                                justifyContent={'center'}
                                borderBottomLeftRadius={"lg"}
                                borderBottomRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'clear'}}>
                                <Text fontSize={'xl'}
                                      bold
                                      _light={{color: 'tx.negativeValue'}}>
                                    {Math.abs(dataExtratoCashBack === undefined ? 0 : dataExtratoCashBack.extrato.filter((el) => (el.POSICAO === 6))[0].VLRLANCAMENTO || 0).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </Text>
                            </Box>
                        </VStack>

                        <VStack
                            flex={1}
                            mr={{md: 2}}
                            mb={4}
                            width={{base: '-webkit-fill-available', md: ''}}
                            height={{md: 115}}
                        >
                            <Box
                                padding={2}
                                borderTopLeftRadius={"lg"}
                                borderTopRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'bg.attention'}}>
                                <Text fontSize={{base: 'md', md: 'xl'}}
                                      fontWeight="semibold"
                                      _light={{color: 'clear'}}
                                >A Liberar</Text>
                            </Box>
                            <Box
                                flex={1}
                                justifyContent={'center'}
                                mb={2}
                                padding={2}
                                borderBottomLeftRadius={"lg"}
                                borderBottomRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'clear'}}>
                                <Text fontSize={'xl'}
                                      bold
                                      _light={{color: 'tx.attentionValue'}}>
                                    {dataExtratoCashBack === undefined ? 0 : (dataExtratoCashBack.extrato.filter((el) => el.POSICAO === 2)[0].VLRLANCAMENTO || 0).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </Text>
                            </Box>
                        </VStack>


                        <VStack
                            flex={1}
                            mb={4}
                            width={{base: '-webkit-fill-available', md: ''}}
                            height={{md: 115}}
                        >
                            <Box
                                padding={2}
                                borderTopLeftRadius={"lg"}
                                borderTopRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'bg.primary'}}>
                                <Text fontSize={{base: 'md', md: 'xl'}}
                                      fontWeight="semibold"
                                      _light={{color: 'clear'}}
                                >Saldo Atual</Text>
                            </Box>
                            <Box
                                flex={1}
                                justifyContent={'center'}
                                mb={2}
                                padding={2}
                                borderBottomLeftRadius={"lg"}
                                borderBottomRightRadius={"lg"}
                                alignItems={'center'}
                                _light={{bg: 'clear'}}>
                                <Text fontSize={'xl'}
                                      bold
                                      _light={{color: 'tx.primaryValue'}}>
                                    {dataExtratoCashBack === undefined ? 0 : (dataExtratoCashBack.extrato.filter((el) => (el.POSICAO === 4))[0].VLRLANCAMENTO || 0).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}

                                </Text>
                                <Text
                                    fontWeight={'semibold'}
                                    fontSize='xm'
                                    _light={{color: 'tx.subTitle'}}
                                >
                                    (Disponível + A Liberar)
                                </Text>
                            </Box>
                        </VStack>

                    </Stack>
                </ScrollView>
            </>
        );
    };

    const routeTwo = () => {
        return (
            <>
                <ScrollView maxHeight={"70vh"} showsHorizontalScrollIndicator={true}>
                    {dataExtratoCashBack?.extrato
                        .filter(
                            (el) =>
                                (el.SINAL === '-' || el.SINAL === '+' || el.SINAL === '=') &&
                                (el.MES !== 99)
                        )
                        .map((extrato, index) => {
                            return (
                                <CardExtract extrato={extrato} key={index}/>
                                // <Pressable key={index}>
                                //     <CardExtract extrato={extrato} key={index}/>
                                // </Pressable>
                            );
                        })}
                </ScrollView>
            </>)
    };

    const routeThree = () => (
        <>
            <ScrollView maxHeight={"70vh"} showsHorizontalScrollIndicator={true}>
                {dataExtratoCashBack?.extrato
                    .filter((el) => el.SINAL === '+' && el.STATUSAGENDA === 'F')
                    .map((extrato, index) => {
                        return (
                            <CardExtract extrato={extrato} key={index}/>
                            // <Pressable key={index}>
                            //     <CardExtract extrato={extrato} key={index}/>
                            // </Pressable>
                        );
                    })}
            </ScrollView>
        </>
    );

    const routefour = () => (
        <>
            <ScrollView maxHeight={"70vh"} showsHorizontalScrollIndicator={true}>
                {dataExtratoCashBack?.extrato
                    .filter((el) => el.SINAL === '-' && el.STATUSAGENDA === 'F')
                    .map((extrato, index) => {
                        return (
                            <CardExtract extrato={extrato} key={index}/>
                            // <Pressable key={index}>
                            //     <CardExtract extrato={extrato} key={index}/>
                            // </Pressable>
                        );
                    })}
            </ScrollView>
        </>
    );

    const routeFive = () => (
        <>
            <ScrollView maxHeight={"70vh"} showsHorizontalScrollIndicator={true}>
                {dataExtratoCashBack?.extratoProximaFaixa.map((extrato, index) => {
                    return (
                        <CardNextExtract extrato={extrato} key={index}/>
                        // <Pressable key={index}>
                        //     <CardNextExtract extrato={extrato} key={index}/>
                        // </Pressable>
                    );
                })}
            </ScrollView>
        </>
    );

    const initialLayout = {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    };

    const renderScene = SceneMap({
        one: routeOne,
        two: routeTwo,
        three: routeThree,
        four: routefour,
        five: routeFive,
    });

    const consultarExtrato = async (): Promise<void> => {
        if (dataExtratoCashBack) return

        await fetcher<TExtratoCashBack>(
            'POST',
            'cashback/extrato/extrato',
            {
                TOKEN: session.usuarioPortal.TOKEN,
                SINTETICO: false,
            }
        )
            .then((res) => {
                if (res.ok) {
                    if (res.return.data !== undefined) {
                        setExtratoCashBack(res.return.data)
                    }
                }
            })
            .catch((err) => {
                console.log((err as Error).message);
            });
    };

    React.useEffect(() => {
        const execute = async () => {
            await consultarExtrato();
        }
        execute();
    }, []);

    return (
        <>
            <HomeLayout buttonSelect={0}>
                <TicketCard/>
                <VStack
                    _light={{bg: 'bg.primary'}}
                    borderRadius={{base: '8'}}
                    p={{base: 4}}
                    mb={{md: 2}}
                >
                    <HStack alignItems="center" justifyContent="space-between">
                        <VStack space="1">
                            <Text
                                fontSize="sm"
                                color="clear"
                                fontWeight="semibold"
                            >
                                Evolução do seu CashBack
                            </Text>
                        </VStack>
                    </HStack>
                </VStack>
                <TabView
                    navigationState={{
                        index,
                        routes,
                    }}
                    lazy={true}
                    renderScene={renderScene}
                    renderTabBar={renderTabBar}
                    onIndexChange={setIndex}
                    initialLayout={initialLayout}
                />
            </HomeLayout>
        </>
    );
}
