import axios, { Method } from 'axios'

export type Headers = {
    'Content-Type': string;
    Authorization?: string;
}

export interface Response<TResult> {
    ok: boolean;
    status: number;
    headers: unknown;
    mensagem: undefined;
    return: {
        status: boolean;
        msg: string;
        data: TResult | undefined;
        others: unknown;
    }
}

export default async function fetcher<TResult>(
    method: Method,
    sufix: string,
    data?: unknown
): Promise<Response<TResult>> {
    //const jwt: string | undefined = store.state.jwt
    const jwt: string | undefined = undefined
     const url = 'https://server.grupopalmed.com.br:4001/' + sufix;
     //xconst url = 'http://localhost:4002/' + sufix;

    const headers: Headers = {
        'Content-Type': 'application/json; charset=utf-8',
    }

    if (jwt) {
        headers.Authorization = 'Bearer ' + jwt
    }

    const res = await axios({
        headers,
        method,
        url,
        data,
        validateStatus: () => true // don't throw on non-200
    })
    return {
        ok: res.status >= 200 && res.status < 400,
        status: res.status,
        headers: res.headers,
        return: {
            status: res.data.STATUS,
            msg: res.data.MSG,
            data: res.data ? (res.data.DATA as TResult) : undefined,
            others: res.data ? res.data.OTHER : undefined,
        }
    } as Response<TResult>
}
