import React from "react";
import {
    Box,
    VStack,
    Image,
    StatusBar,
    Center,
    Text,
} from "native-base";

export default function AvisoCliente(props: any) {
    return (
        <>
            <StatusBar
                translucent
                backgroundColor="transparent"
                barStyle="light-content"
            />
            <Box
                safeAreaTop
                _light={{bg: "bg.secundary"}}
            />

            <VStack
                alignItems="center"
                w="100%"
                flex="1"
                space="4"
                justifyContent="center"
                _light={{bg: "bg.secundary"}}
            >
                <Center>
                    <Image
                        size={{base: "64"}}
                        resizeMode={"contain"}
                        alt="NativeBase Logo"
                        source={require("../../images/logo.png")}
                    />
                </Center>

                <Center
                    py={{
                        base: "4",
                        md: "4",
                    }}
                >
                    <Text fontSize="4xl" fontWeight="bold" color="tx.primaryValue">
                        Aviso Importante!
                    </Text>
                </Center>

                <Center justifyContent="center"
                        py={{
                            base: "16",
                            md: "16",
                        }}>
                    <Text fontSize="xl" fontWeight="bold" color="tx.primaryValue">
                        Este programa é direcionado para os pequenos e médios clientes Palmed.
                    </Text>
                    <Text fontSize="xl" fontWeight="bold" color="tx.primaryValue">
                        A sua empresa é um cliente especial que já tem condições de negociações exclusivas.
                    </Text>
                    <Text fontSize="xl" fontWeight="bold" color="tx.primaryValue">
                        Qualquer dúvida, contatar nosso departamento comercial.
                    </Text>
                    <Text fontSize="xl" fontWeight="bold" color="tx.primaryValue">
                        Atenciosamente.
                    </Text>
                </Center>
            </VStack>;
        </>
    );
}
