import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState,} from 'react';
import fetcher from '../services/fetcher';

export default interface IUsuarioPortalDTO {
  IDUSUARIOPORTAL: number
  IDCLIENTEWINTHOR: number
  RAZAOSOCIAL: string
  FANTASIA: string
  CNPJCPF: string
  TELEFONE: string
  EMAIL: string
  RESP_NOME: string
  RESP_DTNASCIMENTO: string
  RESP_FUNCAO: string
  RESP_TELEFONE: string
  RESP_CELULAR: string
  RESP_CEP: string
  RESP_ENDERECO: string
  RESP_ENDERECO_COMPL: string
  RESP_BAIRRO: string
  RESP_CIDADE: string
  RESP_UF: string
  RESP_EMAIL: string
  RESP_TIMEFUTEBOL: string
  RESP_CHAVEPIX: string
  RESP_SENHA: string
  TOKEN: string
  EXCECAO: string
  ACESSADO_POR_RCA: boolean
}

interface ISessionState {
  usuarioPortal: IUsuarioPortalDTO;
  profile?: any;
}

interface IAuthContext {
  session: ISessionState;
  loginByEmail(
      email: string,
      password: string
  ): Promise<boolean | undefined>;
  loginByToken(
      token: string
  ): Promise<boolean | undefined>;
  loginById(
      id: string,
      acessadoPorRca: boolean
  ): Promise<boolean | undefined>;
  logout(): Promise<void>;
  loading: boolean;
  setLoading: (arg0: boolean) => void;
}

const initialSessionValue: ISessionState = {
  usuarioPortal: { IDUSUARIOPORTAL: 0 } as IUsuarioPortalDTO,
};

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [sessionAuthProvider, setSessionAuthProvider] =
    useState<ISessionState>(initialSessionValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadSession() {
      startSession({
        IDUSUARIOPORTAL: 0
      } as IUsuarioPortalDTO, false).then(r => r);
    }
    loadSession().then(r => r);
  }, []);

  const startSession = useCallback(async (usuarioPortal: IUsuarioPortalDTO, acessadoPorRca: boolean) => {
    let usuario = { ...usuarioPortal };
    usuario.ACESSADO_POR_RCA = acessadoPorRca;
    setSessionAuthProvider({
      usuarioPortal: { ...usuario },
    });
  }, []);

  const endSession = useCallback(async () => {
    setSessionAuthProvider({ ...initialSessionValue });
  }, []);

  const logout = useCallback(async () => {
    try {
      endSession();
    } catch (error) {
      throw error;
    }
  }, [endSession]);

  const loginByEmail = async (email: string, password: string): Promise<boolean> => {
    setLoading(false)
    try {
      const user = await fetcher<IUsuarioPortalDTO>(
        'POST',
        'auth/login',
        {
          email: email,
          password: password,
        }
      ).then((res) => {
        if (res.ok) {
          if (res.return.data !== undefined) {
            res.return.data.TOKEN = '3-1-' + res.return.data?.TOKEN;
          }
        }
        return res.return.data;
      });
      if (user !== undefined) {
        startSession(user, false);
        return true;
      } else return false;
    } finally {
      setLoading(false)
    }
  };

    const loginByToken = async (token: string): Promise<boolean> => {
    setLoading(false)
    try {
      const user = await fetcher<IUsuarioPortalDTO>(
        'POST',
        'auth/login',
        {
          token: token
        }
      ).then((res) => {
        if (res.ok) {
          if (res.return.data !== undefined) {
            res.return.data.TOKEN = `3-1-${res.return.data?.TOKEN}`;
          }
        }
        return res.return.data;
      });
      if (user !== undefined) {
        await startSession(user, false);
        return true;
      } else return false;
    } finally {
      setLoading(false)
    }
  };

    const loginById = async (id: string, acessadoPorRca: boolean): Promise<boolean> => {
    setLoading(false)
    try {
      const user = await fetcher<IUsuarioPortalDTO>(
        'POST',
        'auth/loginPorId',
        {
          id: id
        }
      ).then((res) => {
        if (res.ok) {
          if (res.return.data !== undefined) {
            res.return.data.TOKEN = `3-1-${res.return.data?.TOKEN}`;
          }
        }
        return res.return.data;
      });
      if (user !== undefined) {
        await startSession(user, acessadoPorRca);
        return true;
      } else return false;
    } finally {
      setLoading(false)
    }
  };

  return (
    <AuthContext.Provider
      value={{
        session: sessionAuthProvider,
        loginByEmail,
        loginByToken,
        loginById,
        logout,
        loading,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error('AuthContext não foi encontrado!');
  }

  return ctx;
};
